import React from 'react';
import { Redirect } from 'react-router-dom';
import RecipeShow from './RecipeShow';

export default class RecipeDelete extends React.Component {
    constructor(props) {
        super(props);
        this.props = props;
        this.state = {
            recipeId: '',
            recipeTitle: '',
            dishPhotos:[],
            dishType: '',
            recipeCategories: '',
            recipeInstructions: '',
            ingredientGroups: [{ id: 0, igroupName: 'Group 1', ingredients: [{ id: 0, qty: '', uom: '', ingredient: '' }] }],
            enteredByEmail: '',
            redirect: false,
        };
        this.deleteRecipe = this.deleteRecipe.bind(this);
        this.cancelDeletion = this.cancelDeletion.bind(this);
    }

    componentDidMount = () => {
        const { match: { params } } = this.props;
        let firestore = this.props.firestore;
        let instate = this.state;
        instate.recipeId = params.recipeId;
        var docRef = firestore.collection(this.props.userActiveBook.bookName).doc(this.state.recipeId);
        docRef.get().then((doc) => {
            if (doc.exists) {
                //console.log("Document data:", doc.data());
                var data = doc.data();
                instate.recipeTitle = data.recipeTitle;
                instate.dishType = data.dishType;
                instate.recipeCategories = data.recipeCategories.toString();
                instate.recipeInstructions = data.recipeInstructions;
                instate.ingredientGroups = data.ingredientGroups;
                instate.enteredByEmail = data.enteredByEmail;
                this.setState(instate);
            } else {
                console.log("No such recipe found!");
            }
        }).catch(function (error) {
            console.log("Error getting document:", error);
        });
    }

    deleteRecipe() {
        if (this.state.enteredByEmail === this.props.workerEmail || this.props.bookOwner.includes(this.props.workerEmail)) {
            var docRef = this.props.firestore.collection(this.props.userActiveBook.bookName).doc(this.state.recipeId);
            docRef.delete().then((docRef) => {
                console.log("Recipe Document deleted with ID ", this.state.recipeId);
                alert('Recipe successfully deleted :)');
                this.setState({ redirect: true });
            })
            .catch((error) => {
                console.error("Error deleting document: ", error);
            });
        }
        else {
            alert('you are not authorized to delete this recipe');
        }
    }

    cancelDeletion() {
        this.setState({ redirect: true });
    }

    render() {
        if (this.state.redirect) {
            return <Redirect push to="/showrecipes" />;
        }
        return (
            <div id="show-recipe" className="container col-12">
                <div className="card">
                    <h1 className="card-header"> Delete this recipe from {this.props.userActiveBook.bookName} Recipe Book?</h1>
                    <div className="card-body">
                        <RecipeShow
                            recipeTitle={this.state.recipeTitle}
                            dishType={this.state.dishType}
                            recipeCategories={this.state.recipeCategories}
                            recipeInstructions={this.state.recipeInstructions}
                            ingredientGroups={this.state.ingredientGroups}
                            enteredByEmail={this.state.enteredByEmail}
                        />
                        <div className="row">
                            <div className="col-12">
                                <button onClick={this.cancelDeletion} className="btn btn-warning">Cancel</button>
                                <button onClick={this.deleteRecipe} className="btn btn-danger">Delete</button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}