import React from 'react';
export default class FormErrors extends React.Component {
    constructor(props) {
      super(props);
      this.props = props;
      this.displayErrors = this.displayErrors.bind(this);
    }
  
    displayErrors() {
      let formErrors = this.props.formErrors;
      const errors = (<div className="card bg-warning iformErrors">
        <div className='card-body'>
          {Object.keys(formErrors).map( (fieldName, i) => {
            if (formErrors[fieldName].length > 0) {
              return (
                <p key={i}>{formErrors[fieldName]}</p>
              )
            } else {
              return '';
            }
          })}
        </div>
      </div>);
      return errors;
    }
  
    render() {
        if (this.props.formValid){
          return (<span></span>);
        }
        return (this.displayErrors());
    }
  }