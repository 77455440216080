import React from 'react';
import RecipesListWidget from './RecipesListWidget.js';

export default class RecipesList extends React.Component {
    //ingredientGroups: [{ id: 0, igroupName: 'Group 1', ingredients: [{ id: 0, qty: '', uom: '', ingredient: '' }] }],
    constructor(props) {
        super(props);
        this.props = props;
        this.state = {
            dishTypeCollection: [],
            recipeCount: 0,
        };
        let firestore = this.props.firestore;
        let bookName = this.props.userActiveBook.bookName;
        let instate = this.state;
        let previousDishType = 'begin';
        let previousRecipes = [];
        //let recipe_count = 0;

        firestore.collection(bookName).orderBy("dishType").orderBy("recipeTitle").get()
            .then((querySnapshot) => {
                querySnapshot.forEach((doc) => {
                    //console.log(doc);
                    instate.recipeCount++;
                    var data = doc.data();
                    if (previousDishType === data.dishType) {
                        previousRecipes.push({ recipeId: doc.id, recipeTitle: data.recipeTitle, dishType: data.dishType.replace(/_/g, "/"), recipeCategories: data.recipeCategories, ingredientGroups: data.ingredientGroups, recipeInstructions: data.recipeInstructions, enteredByEmail: data.enteredByEmail });
                    } else {
                        if (previousDishType !== 'begin') {
                            instate.dishTypeCollection.push({ dishTypeVisible: false, dishType: previousDishType.replace(/_/g, "/").concat(" (" + previousRecipes.length + " total)"), recipes: previousRecipes.slice() });
                        }
                        previousRecipes = [];
                        previousDishType = data.dishType;
                        previousRecipes.push({ recipeId: doc.id, recipeTitle: data.recipeTitle, dishType: data.dishType.replace(/_/g, "/"), recipeCategories: data.recipeCategories, ingredientGroups: data.ingredientGroups, recipeInstructions: data.recipeInstructions, enteredByEmail: data.enteredByEmail });
                    }
                });
                instate.dishTypeCollection.push({ dishTypeVisible: false, dishType: previousDishType.replace(/_/g, "/").concat(" (" + previousRecipes.length + " total)"), recipes: previousRecipes.slice() });
                this.setState(instate);
            });
    }

    componentDidMount = () => {

    }

    render() {
        return (
            <RecipesListWidget
                dishTypeCollection={this.state.dishTypeCollection}
                recipeCount={this.state.recipeCount}
                userActiveBook={this.props.userActiveBook}
                searchText = 'All'
            >
            </RecipesListWidget>
        );
    }
}