import React from 'react';

export default class RecipesBooks extends React.Component {
    constructor(props) {
        super(props);
        this.props = props;
        this.state = {
            newBookName: '',
            newUserAccessGmail: [],
        };
        this.updateUserTyping = this.updateUserTyping.bind(this);
        this.updateEmailEntered = this.updateEmailEntered.bind(this);
        this.clearAddBookForm = this.clearAddBookForm.bind(this);
        this.setState = this.setState.bind(this);
    }

    componentDidMount = () => {
        let instate = this.state;
        instate.newUserAccessGmail = [];
        for( var i=0; i < this.props.userOwnedBooks.length; i++){
            instate.newUserAccessGmail.push('');
        }
        this.setState(instate);
    }

    clearAddBookForm() {
        let instate = this.state;
        instate.newBookName = '';
        this.setState(instate);
    }

    updateEmailEntered(event, i){
        let instate = this.state;
        const value = event.target.value;
        instate.newUserAccessGmail[i] = value;
        this.setState(instate);
    }

    updateUserTyping(event) {
        let instate = this.state;
        const name = event.target.name;
        const value = event.target.value;
        instate[name] = value;
        this.setState(instate);
    }
    render() {
        //let userBooks = this.props.userBooks;
        let userActiveBook = this.props.userActiveBook;
        return (
            <div id="recipe-books">
                <div className="container">
                    <div className="card">
                        <h1 className="card-header">Your Recipe Books</h1>
                        <div className="card-body">
                            <div className="h4">Select your active recipe book</div>
                            <div id="active-book">
                                {Object.keys(this.props.userBooks).map((fieldName, i) => {
                                    let book = this.props.userBooks[fieldName];
                                    return (
                                        <div key={i} className="row form-check">
                                            <label>
                                                <input
                                                    type="radio"
                                                    name="userActiveBook"
                                                    value={book.bookName}
                                                    checked={book.bookName === userActiveBook.bookName}
                                                    onChange={() => this.props.onActiveBookChange({'bookId': book.bookId, 'bookName': book.bookName})}
                                                    className="form-check-input input-sm"
                                                />
                                                {book.bookName}
                                            </label>
                                        </div>
                                    );
                                }
                                )}
                            </div>
                        </div>
                        <div className="card-body col-12">
                            <div className="h4">Create recipe book</div>
                            <div className="row">
                                <div className="input-group col-12">
                                    <input
                                        name="newBookName"
                                        value={this.state.newBookName}
                                        onChange={(event => this.updateUserTyping(event))}
                                        className="form-control form-control-sm"
                                        type="text" placeholder="New book name">
                                    </input>
                                    <div className="input-group-append">
                                        <button onClick={() => this.props.onCreateBook(this.state.newBookName)} id="add-button" className="btn btn-primary btn-sm">Add Book</button>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="card-body col-12">
                            <div className="h4">Books you own</div>
                            <div className="row">
                                {Object.keys(this.props.userOwnedBooks).map((fieldName, i) => {
                                    let book = this.props.userOwnedBooks[fieldName];
                                    let button = '';
                                    if (book.bookName !== 'mcdonald' && book.bookName !== 'world') {
                                        button = <button onClick={() => this.props.onDeleteBook(book.bookId)} id="delete-book" className="btn btn-danger btn-sm">Delete book</button>
                                    }
                                    return (
                                        <div className="col-12" key={i}>
                                            <div>{book.bookName}</div>
                                            <div className="input-group">
                                                <input
                                                    name={"newUserAccessGmail".concat(i)}
                                                    value={this.state.newUserAccessGmail[i] || ''}
                                                    onChange={event => this.updateEmailEntered(event, i)}
                                                    className="form-control form-control-sm"
                                                    type="text"
                                                    placeholder="user's gmail address">
                                                </input>
                                                <div className="input-group-append">
                                                    <button onClick={() => this.props.onGiveAccess(this.state.newUserAccessGmail[i], book.bookId, book.bookName)} id="give-user-access" className="btn btn-primary btn-sm">Grant access</button>
                                                </div>
                                            </div>
                                            {button}
                                            <hr></hr>
                                        </div>
                                    );
                                })}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}