import React from 'react';
import { Link } from 'react-router-dom';
import { Redirect } from 'react-router-dom';

export default class RecipesListWidget extends React.Component {

    constructor(props) {
        super(props);
        this.props = props;
        this.state = {
            dishTypeCollection: props.dishTypeCollection,
            displayRecipe: false,
            displayRecipeId: '',
        };
        this.displayRecipe = this.displayRecipe.bind(this);
        this.toggleDishType = this.toggleDishType.bind(this);
    }

    displayRecipe = (rid) => {
        this.setState({
            displayRecipe: true,
            displayRecipeId: rid
        });
    }

    toggleDishType = (dishType) =>{
        let dtc = this.state.dishTypeCollection;
        dtc.forEach( (dt) =>{
            if(dt.dishType === dishType){
                dt.dishTypeVisible = !dt.dishTypeVisible;
            } 
        });
        this.setState({dishTypeCollection:dtc});
    }

    render() {
        let editLink = '';
        let deleteLink = '';
        if (this.state.displayRecipe) {
            return <Redirect push to={`/displayrecipe/${this.state.displayRecipeId}`} />;
        }
        return (
            <div id="show-recipes">
                <div className="container col-12">
                    <div className="card">
                        <h1 className="card-header"><span className="current-book">{this.props.userActiveBook.bookName}</span> Recipe Book - {this.props.recipeCount} - {this.props.searchText}</h1>
                        <div className="card-body">
                            <div>
                                <table className="table table-sm table-bordered table-hover">
                                    <thead>
                                        <tr>
                                            <th>Dish Type</th>
                                            <th>Recipe Title</th>
                                            <th>Recipe Categories</th>
                                            <th>Recipe Instructions</th>
                                            <th>Entered by</th>
                                            <th>Edit</th>
                                            <th>Delete</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {
                                            Object.keys(this.state.dishTypeCollection).map((fieldName, i) => {
                                                let dishType = this.state.dishTypeCollection[i].dishType;
                                                let dishTypeVisible = this.state.dishTypeCollection[i].dishTypeVisible;
                                                let dish_recipes = this.state.dishTypeCollection[i].recipes;
                                                if (!dishTypeVisible) {
                                                    return (
                                                        <React.Fragment key={i}>
                                                            <tr>
                                                                <td onClick={() => this.toggleDishType(dishType)} className="dishTypeHeader" colSpan="7">{dishType}</td>
                                                            </tr>
                                                        </React.Fragment>
                                                    );
                                                } else {
                                                    return (
                                                        <React.Fragment key={i}>
                                                            <tr>
                                                                <td onClick={() => this.toggleDishType(dishType)} className="dishTypeHeader" colSpan="7">{dishType}</td>
                                                            </tr>
                                                            {
                                                                Object.keys(dish_recipes).map((fieldName, x) => {
                                                                    let recipe = dish_recipes[fieldName];
                                                                    //console.log('enteredByEmail ', recipe.enteredByEmail);
                                                                    //console.log('workerEmail ', this.props.workerEmail);
                                                                    //console.log('bookOwner ', this.props.bookOwner);
                                                                    if (recipe.enteredByEmail === this.props.workerEmail || this.props.workerEmail === this.props.bookOwner) {
                                                                        editLink = (<Link to={`/editrecipe/${recipe.recipeId}`}>Edit</Link>);
                                                                        deleteLink = (<Link to={`/deleterecipe/${recipe.recipeId}`}>Delete</Link>);
                                                                    }
                                                                    return (
                                                                        <tr key={"".concat(i).concat(".").concat(x)} onClick={() => this.displayRecipe(recipe.recipeId)}>
                                                                            <td>{recipe.dishType}</td>
                                                                            <td>{recipe.recipeTitle}</td>
                                                                            <td>{recipe.recipeCategories}</td>
                                                                            <td>{recipe.recipeInstructions.substring(0, 25)}</td>
                                                                            <td>{recipe.enteredByEmail.substring(0, recipe.enteredByEmail.indexOf("@"))}</td>
                                                                            <td>{editLink}</td>
                                                                            <td>{deleteLink}</td>
                                                                        </tr>
                                                                    );
                                                                })
                                                            }
                                                        </React.Fragment>
                                                    );
                                                }
                                            })
                                        }
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}