import React from 'react';
import { Redirect } from 'react-router-dom';
import RecipeShow from './RecipeShow';
import Popup from 'reactjs-popup';

export default class RecipeDisplay extends React.Component {
    constructor(props) {
        super(props);
        this.props = props;
        this.state = {
            recipeId: '',
            recipeTitle: '',
            dishPhotos:[],
            dishType: '',
            recipeCategories: '',
            recipeInstructions: '',
            ingredientGroups: [{ id: 0, igroupName: 'Group 1', ingredients: [{ id: 0, qty: '', uom: '', ingredient: '' }] }],
            enteredByEmail: '',
            redirect: false,
            targetBooks: []
        };
        this.clickOK = this.clickOK.bind(this);
        this.addRecipeToAnotherBook = this.addRecipeToAnotherBook.bind(this);
        this.bookCheckbox = this.bookCheckbox.bind(this);
        this.closeModal = this.closeModal.bind(this);   
        this.customStyles = {
            'display': 'flex',
            'alignItems': 'center',                
            'borderRadius': '8px',
            'width':'350px',
        }               
    }

    bookCheckbox(event) {
        let targetBooks = this.state.targetBooks;
        const value = event.target.value;
        if (targetBooks.includes(value)) {
            for (var i = 0; i < targetBooks.length; i++) {
                if (targetBooks[i] === value) {
                    targetBooks.splice(i, 1);
                }
            }
            //alert(value.concat(' removed'));
        } else {
            targetBooks.push(value);
            //alert(value.concat(' added'));
        }
        this.setState({ targetBooks: targetBooks });
    }
    closeModal(close) {
        this.setState({ targetBooks: [] });
        close();
    }

    addRecipeToAnotherBook = (event, close) => {
        let targetBooks = this.state.targetBooks;
        if (targetBooks.length === 0) {
            alert('You must select a book in order to copy');
        } else {
            targetBooks.forEach((book) => {
                this.props.firestore.collection(book).add({
                    recipeTitle: this.state.recipeTitle,
                    dishType: this.state.dishType,
                    recipeCategories: this.state.recipeCategories.split(','),
                    ingredientGroups: this.state.ingredientGroups,
                    recipeInstructions: this.state.recipeInstructions,
                    enteredByEmail: this.props.workerEmail
                })
                    .then((docRef) => {
                        console.log("Document written with ID ", docRef.id);
                        alert('Add complete to '.concat(book));
                    })
                    .catch((error) => {
                        console.error("Error adding document: ", error);
                    });
            });
            this.setState({ targetBooks: [] });
            close();
        }
    }

    componentDidMount = () => {
        const { match: { params } } = this.props;
        let firestore = this.props.firestore;
        let instate = this.state;
        instate.recipeId = params.recipeId;
        var docRef = firestore.collection(this.props.userActiveBook.bookName).doc(this.state.recipeId);
        docRef.get().then((doc) => {
            if (doc.exists) {
                //console.log("Document data:", doc.data());
                var data = doc.data();
                instate.recipeTitle = data.recipeTitle;
                instate.dishType = data.dishType;
                instate.recipeCategories = data.recipeCategories.toString();
                instate.recipeInstructions = data.recipeInstructions;
                instate.ingredientGroups = data.ingredientGroups;
                instate.enteredByEmail = data.enteredByEmail;
                this.setState(instate);
            } else {
                console.log("No such recipe found!");
            }
        }).catch(function (error) {
            console.log("Error getting document:", error);
        });
    }

    clickOK = () => {
        this.setState({ redirect: true });
    }

    render = () => {
        
        if (this.state.redirect) {
            return <Redirect push to="/showrecipes" />;
        }
        return (
            <div id="show-recipe" className="container col-12">
                <div className="card">
                    <div className="card-header">
                        <span className="h2 align-middle">From <span className="current-book">{this.props.userActiveBook.bookName}</span> Recipe Book</span>&nbsp;&nbsp; 
                    <Popup
                        contentStyle={this.customStyles}
                        trigger={<button className="btn btn-primary btn-sm">Add to another Recipe Book</button>}
                        modal
                        closeOnDocumentClick
                        closeOnEscape
                    >{close => (
                        <div id="add-to-book" className="container">
                            <div className="row">Select the books for a copy of the recipe</div>
                            {Object.keys(this.props.userBooks).map((fieldName, i) => {
                                let bookName = this.props.userBooks[fieldName].bookName;
                                if (bookName === this.props.userActiveBook.bookName) {
                                    return '';
                                } else {
                                    return (
                                        <div key={i} className="row form-check">
                                            <label>
                                                <input
                                                    type="checkbox"
                                                    name={bookName}
                                                    value={bookName}
                                                    onChange={(event) => this.bookCheckbox(event)}
                                                    className="form-check-input"
                                                />
                                                {bookName} Recipe Book
                                            </label>
                                        </div>
                                    );
                                }
                            }
                            )}
                            <div className="row">
                                <button className="btn btn-warning" onClick={() => this.closeModal(close)}>Cancel Copy</button>
                                <button onClick={(event) => this.addRecipeToAnotherBook(event, close)} id="submit-button" className="btn btn-primary">Copy</button>
                            </div>
                        </div>
                    )}
                    </Popup>
                    </div>
                    <div className="card-body col-12">
                        <RecipeShow
                            recipeTitle={this.state.recipeTitle}
                            dishType={this.state.dishType}
                            recipeCategories={this.state.recipeCategories}
                            recipeInstructions={this.state.recipeInstructions}
                            ingredientGroups={this.state.ingredientGroups}
                            enteredByEmail={this.state.enteredByEmail}
                        />
                        <div className="row">
                            <div className="col-12">
                                <button onClick={this.clickOK} className="btn btn-primary">OK</button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}