import React from 'react';
export default class Ingredient extends React.Component {
    constructor(props) {
      super(props);
      this.props = props;
    }
  
    render() {
      return (
        <div className="row">
          <div className="col-3">
            <input name="qty" className="form-control form-control-sm text-center" type="text" placeholder="quantity"
              onChange={event => this.props.onClick(event)}
              value={this.props.qty}></input>
          </div>
          <div className="col-3">
            <select
              name="uom"
              value={this.props.uom}
              className="form-control form-control-sm"
              onChange={event => this.props.onClick(event)}>
              <option value="">unit of measure</option>
              <option value="bag">bag(s)</option>
              <option value="bottle">bottle(s)</option>
              <option value="bunch">bunch(es)</option>
              <option value="can">can(s)</option>
              <option value="clove">clove(s)</option>
              <option value="cup">cup(s)</option>
              <option value="dash">dash(es)</option>
              <option value="drizzle">drizzle(s)</option>
              <option value="floz">fluid ounce(s)</option>
              <option value="gallon">gallon(s)</option>
              <option value="gram">gram(s)</option>
              <option value="milliliter">milliliter(s)</option>
              <option value="ounce">ounce(s)</option>
              <option value="pinch">pinch(es)</option>
              <option value="package">package(s)</option>
              <option value="part">part(s)</option>
              <option value="pint">pint(s)</option>
              <option value="pound">pound(s)</option>
              <option value="quart">quart(s)</option>
              <option value="tsp">teaspoon(s)</option>
              <option value="tbsp">tablespoon(s)</option>              
              <option value="whole">whole</option>
            </select>
          </div>
          <div className="col-6">
            <input
              name="ingredient"
              value={this.props.children}
              onChange={event => this.props.onClick(event)}
              className="form-control form-control-sm" type="text" placeholder="ingredient"></input>
          </div>
        </div>
      );
    }
  }