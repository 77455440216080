import React from 'react';

export default class MAFHeader extends React.Component {
    constructor(props) {
      super(props);
      this.props = props;
    }
  
    render() {
        return (
          <div id="app-header">
            <img src="/maf_logo.png" height="51px" width="51px" className="app-logo" alt="logo" />
            <div className="app-title">mealsare.fun</div>
          </div>
        );
      }
}