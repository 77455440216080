import React from 'react';
import './App.css';
import RecipeAdd from './RecipeAdd.js';
import RecipeEdit from './RecipeEdit.js';
import RecipeDelete from './RecipeDelete.js';
import RecipesList from './RecipesList';
import RecipesSearch from './RecipesSearch.js';
import RecipeBooks from './RecipeBooks.js';
import RecipeHome from './RecipeHome.js';
import RecipeDisplay from './RecipeDisplay.js';
import MAFHeader from './MAFHeader.js';
import firebase from 'firebase/app';
import { BrowserRouter as Router, Route, Switch, NavLink, Redirect } from 'react-router-dom';

require('firebase/auth');
require('firebase/firestore');
require('firebase/storage');

// Your web app's Firebase configuration
var firebaseConfig = {
  apiKey: "AIzaSyA_zI_6n0J9vGHZJCrV-JHQYgKuNoQk1Qc",
  authDomain: "mealsarefun.firebaseapp.com",
  databaseURL: "https://mealsarefun.firebaseio.com",
  projectId: "mealsarefun",
  storageBucket: "mealsarefun.appspot.com",
  messagingSenderId: "697766327035",
  appId: "1:697766327035:web:acb50dd5176e96ad2d765e"
};

// Initialize Firebase
firebase.initializeApp(firebaseConfig);
var firestore = firebase.firestore();
var storage = firebase.storage();

class MAFSignIn extends React.Component {

  constructor(props) {
    super(props);
    this.props = props;
    this.glabel = React.createRef();
    this.gname = React.createRef();
    this.gbuttonText = React.createRef();
  }

  componentDidMount = () => {
    firebase.auth().onAuthStateChanged((user) => {
      if (user) {
        this.glabel.current.innerText = "";
        this.gname.current.innerText = "Logged in as " + user.email.substring(0, user.email.indexOf("@"));
        this.gbuttonText.current.innerText = "Log out";
        console.log(user.email, user.displayName);
        console.log(user.photoURL);
        this.props.authStateChanged(user);
      } else {
        // No user is signed in.
        this.glabel.current.innerText = "Sign in with ";
        this.gbuttonText.current.innerText = "Google";
        this.gname.current.innerText = "";
        this.props.authStateChanged(user);
      }
    });
  }

  render = () => {
    return (
      <div id="maf-signin">
        <div id="gSignInWrapper">
          <span ref={this.glabel} id="g-label"></span>
          <div id="g-customBtn" onClick={this.props.userInitiatedGoogleAuthChange}>
            <span id="g-normal"></span>
            <span ref={this.gbuttonText} id="g-buttonText"></span>
          </div>
        </div>
        <div ref={this.gname} id="g-name"></div>
      </div>
    );
  };
}

class App extends React.Component {

  constructor(props) {
    super(props);
    this.props = props;
    this.state = {
      workerEmail: undefined,
      user: undefined,
      userId: undefined,
      loggedIn: 'no',
      userBooks: [],
      userOwnedBooks: [],
      userActiveBook: undefined,
      bookCreator:undefined,
      bookOwner: [],
      accessToken: undefined,
      searchText: '',
      runSearch: false,
    };
    this.userInitiatedGoogleAuthChange = this.userInitiatedGoogleAuthChange.bind(this);
    this.authStateChange = this.authStateChange.bind(this);
    this.workerActiveBookChange = this.workerActiveBookChange.bind(this);
    this.handleAddBook = this.handleAddBook.bind(this);
    this.handleGiveUserAccess = this.handleGiveUserAccess.bind(this);
    this.handleDeleteBook = this.handleDeleteBook.bind(this);
    this.handleSearchTyping = this.handleSearchTyping.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.resetState = this.resetState.bind(this);
  }

  resetState = () => {
    this.setState({
      workerEmail: undefined,
      user: undefined,
      userId: undefined,
      loggedIn: 'no',
      userBooks: [],
      userOwnedBooks: [],
      userActiveBook: undefined,
      bookCreator: undefined,
      bookOwner: [],
      accessToken: undefined,
      searchText: '',
      runSearch: false,
    });
  }

  userInitiatedGoogleAuthChange = () => {
    let instate = this.state;
    if (instate.loggedIn === 'yes') {
      firebase.auth().signOut().then(() => {
        this.resetState();
        console.log('Signed Out');
      }, function (error) {
        console.error('Sign Out Error', error);
      });
    } else {
      var provider = new firebase.auth.GoogleAuthProvider();
      firebase.auth().signInWithRedirect(provider);
      firebase.auth().getRedirectResult().then((result) => {
        if (result.credential) {
          // This gives you a Google Access Token. You can use it to access the Google API.
          instate.accessToken = result.credential.accessToken;
        }
        instate.user = result.user;
        instate.workerEmail = result.user.email;
        instate.loggedIn = 'yes';
        firestore.collection("users").where("userEmail", "==", instate.workerEmail).get()
          .then((querySnapshot) => {
            if (querySnapshot.size > 0) {
              querySnapshot.forEach((doc) => {
                var data = doc.data();
                console.log("user is found: ", data.userEmail);
                instate.userBooks = data.userBooks;
                instate.userOwnedBooks = data.userOwnedBooks;
                instate.userActiveBook = data.userActiveBook;
                instate.userId = doc.id;
                console.log("userActiveBook is set :", instate.userActiveBook);
              });
            } else {
              console.log("user not found, adding...");
              firestore.collection("users").add({
                userEmail: instate.workerEmail,
                userActiveBook: { bookId: 'fegFX97iANdU7cVrJ4nx', bookName: "world" },
                userBooks: [{ bookId: 'fegFX97iANdU7cVrJ4nx', bookName: "world" }],
                userOwnedBooks: []
              })
                .then((docRef) => {
                  console.log("New user added ", docRef.id);
                  instate.userActiveBook = docRef.data().userActiveBook;
                  instate.userId = docRef.id;
                  instate.userBooks = docRef.data().userBooks;
                  instate.userOwnedBooks = docRef.data().userOwnedBooks;
                  this.setState(instate);
                  console.log("the userid is", this.state.userId);
                })
                .catch((error) => {
                  console.error("Error adding document: ", error);
                });
            }
          })
          .then(() => {
            let docRef2 = firestore.collection("books").doc(this.state.userActiveBook.bookId);
            docRef2.get().then((doc) => {
              if (doc.exists) {
                instate.bookOwner = doc.data().bookOwner;
                instate.bookCreator = doc.data().bookCreator;
                this.setState(instate);
              } else {
                console.log("No such book found!", this.state.userActiveBook.bookId);
                this.setState(instate);
              }
            }).catch(function (error) {
              console.log("Error getting books document:", error);
            });
            console.log("going to set state in getSiteInfo");
          });
        console.log(this.state.user.email);
        console.log(this.state.user.displayName)
      }).catch((error) => {
        var errorCode = error.code;
        var errorMessage = error.message;
        // The email of the user's account used.
        var email = error.email;
        // The firebase.auth.AuthCredential type that was used.
        var credential = error.credential;
        console.log(errorCode, errorMessage, email, credential);
      });
    }
  }

  authStateChange = (user) => {
    let instate = this.state;
    if (user == null) {
      instate.workerEmail = undefined;
      instate.user = undefined;
      instate.loggedIn = 'no';
      this.setState(instate);
    } else {
      instate.user = user;
      instate.loggedIn = 'yes';
      instate.workerEmail = user.email;
      firestore.collection("users").where("userEmail", "==", user.email).get()
        .then((querySnapshot) => {
          if (querySnapshot.size > 0) {
            querySnapshot.forEach((doc) => {
              var data = doc.data();
              instate.userBooks = data.userBooks;
              instate.userOwnedBooks = data.userOwnedBooks;
              instate.userActiveBook = data.userActiveBook;
              instate.userId = doc.id;
            });
          } else {
            instate.userActiveBook = { bookId: 'fegFX97iANdU7cVrJ4nx', bookName: "world" };
            instate.userBooks = [{ bookId: 'fegFX97iANdU7cVrJ4nx', bookName: "world" }];
            instate.userOwnedBooks = [];
            firestore.collection("users").add({
              userEmail: user.email,
              userActiveBook: { bookId: 'fegFX97iANdU7cVrJ4nx', bookName: "world" },
              userBooks: [{ bookId: 'fegFX97iANdU7cVrJ4nx', bookName: "world" }],
              userOwnedBooks: []
            })
              .then((docRef) => {
                instate.userId = docRef.id;
                console.log("New user added ", instate.userId);
                this.setState(instate);
              })
              .catch((error) => {
                console.error("Error adding document: ", error);
              });
          }
        })
        .then(() => {
          let docRef2 = firestore.collection("books").doc(instate.userActiveBook.bookId);
          docRef2.get().then((doc) => {
            if (doc.exists) {
              instate.bookOwner = doc.data().bookOwner;
              instate.bookCreator = doc.data().bookCreator;
              this.setState(instate);
              console.log('bookOwner: ', instate.bookOwner);
              console.log('bookCreator: ', instate.bookCreator);
            } else {
              console.log("No such book found!", instate.userActiveBook.bookId);
              this.setState(instate);
            }
          }).catch(function (error) {
            console.log("Error getting books document:", error);
          });
        });
    }
  }

  handleAddBook(newBookName) {
    let instate = this.state;
    let newBook = {};
    if (newBookName === '') {
      alert("Book name cannot be empty");
    } else {
      firestore.collection("books").where("bookName", "==", newBookName).get()
        .then((querySnapshot) => {
          if (querySnapshot.size > 0) {
            alert("That book already exists, pick a new name");
            console.log("The book already exits ", newBookName);
          } else {
            console.log("The book does NOT already exist, adding ...");
            console.log("the new book name: ", newBookName);
            console.log("the book creator ", this.state.workerEmail);
            firestore.collection("books").add({
              bookCreator: this.state.workerEmail,
              bookName: newBookName,
              bookOwner: [this.state.workerEmail]
            })
              .then((docRef) => {
                console.log("New Book written with ID ", docRef.id);
                newBook = { bookId: docRef.id, bookName: newBookName };
                console.log("the new book ", newBook);
                instate.userBooks.push(newBook);
                instate.userOwnedBooks.push(newBook);
                this.setState(instate);
                //this.clearAddBookForm();
                alert('Book successfully added :)');
                console.log("userid is ", instate.userId)
                firestore.collection("users").doc(this.state.userId).set({
                  userBooks: instate.userBooks,
                  userOwnedBooks: instate.userOwnedBooks
                }, { merge: true })
                  .then(() => {
                    console.log("Book added to user collection");
                    firestore.collection("users").doc('excgvj0UIMrfQG6MHJhC').update({
                      userBooks: firebase.firestore.FieldValue.arrayUnion(newBook)
                    })
                      .then(() => {
                        //console.log("Book added to superuser");
                      });
                  })
                  .catch((error) => {
                    console.error("Error writing document: ", error);
                  });
              })
              .catch((error) => {
                console.error("Error adding document: ", error);
              });
          }
        }).catch(function (error) {
          console.log("Error getting books document:", error);
        });
      this.setState(instate);
    }
  }

  handleGiveUserAccess(email, bkId, bkName) {
    if (email === '' || !email.endsWith("gmail.com")) {
      alert("Email must be a gmail address");
    }
    else {
      firestore.collection("users").where("userEmail", "==", email).get()
        .then((querySnapshot) => {
          if (querySnapshot.size > 0) {
            let doc = querySnapshot.docs[0];
            let data = doc.data();
            let myUserBooks = data.userBooks;
            let alreadyHasAccess = false;
            myUserBooks.forEach((item) => {
              if (item.bookId === bkId) {
                alreadyHasAccess = true;
              }
            });
            if (alreadyHasAccess) {
              console.log(email.concat(' already has access'));
              alert(email.concat(' already has access'));
            } else {
              myUserBooks.push({ bookId: bkId, bookName: bkName });
              //console.log('passed in info bookId: ', bkId);
              //console.log('passed in info bookName: ', bkName);
              //console.log("User exists, adding access: ", doc.id);
              //console.log("myUserBooks: ", myUserBooks);
              firestore.collection("users").doc(doc.id).set({
                userBooks: myUserBooks
              }, { merge: true })
                .then(() => {
                  console.log("Book added to user collection");
                })
                .catch((error) => {
                  console.error("Error writing document: ", error);
                });
            }
          }
          else {
            console.log("The user is not registered, must logon before being added.");
            alert('User must login for the first time before being added to a recipe book');
          }
        })
    }
  }

  handleDeleteBook(id) {
    let instate = this.state;
    console.log('userActiveBook.bookId ', instate.userActiveBook.bookId)
    if (instate.userActiveBook.bookId === id) {
      alert('You need to change your active book in order to delete it.');
    } else {
      for (let i = 0; i < instate.userBooks.length; i++) {
        if (instate.userBooks[i].bookId === id) {
          instate.userBooks.splice(i, 1);
        }
      }
      for (let i = 0; i < instate.userOwnedBooks.length; i++) {
        if (instate.userOwnedBooks[i].bookId === id) {
          instate.userOwnedBooks.splice(i, 1);
        }
      }
      var docRef = firestore.collection("books").doc(id);
      docRef.delete().then((docRef) => {
        console.log("Book deleted with ID ", id);
        //alert('Book successfully deleted :)');
      }).then(() => {
        firestore.collection("users").doc(this.state.userId).set({
          userBooks: instate.userBooks,
          userOwnedBooks: instate.userOwnedBooks
        }, { merge: true })
          .then(() => {
            //console.log("recipe book removed from user");
            this.setState(instate);
          })
          .catch((error) => {
            console.error("Error writing document: ", error);
          });
      })
        .catch((error) => {
          console.error("Error deleting book: ", error);
        });
    }
  }

  handleSubmit(e) {
    this.setState({ runSearch: true });
  }

  handleSearchTyping(e) {
    this.setState({ searchText: e.target.value });
  }

  workerActiveBookChange = (activeBook) => {
    let instate = this.state;
    instate.userActiveBook = activeBook;

    firestore.collection("users").doc(this.state.userId).set({
      userActiveBook: instate.userActiveBook
    }, { merge: true })
      .then(function () {
        console.log("Active book successfully updated");
      })
      .catch(function (error) {
        console.error("Error writing document: ", error);
      });

    firestore.collection("books").doc(instate.userActiveBook.bookId).get()
      .then((doc) => {
        if (doc.exists) {
          instate.bookCreator = doc.data().bookCreator;
          instate.bookOwner = doc.data().bookOwner;
          console.log('bookOwner: ', instate.bookOwner)
          console.log('bookCreator: ', instate.bookCreator)
        } else {
          console.log("No such book found!");
        }
        this.setState(instate);
      }).catch(function (error) {
        console.log("Error getting books document:", error);
      });
  }

  renderBody = () => {
    let searchRedirect = '';
    if (this.state.loggedIn === 'yes') {
      if (this.state.runSearch) {
        searchRedirect = <Redirect push to={`/searchrecipes/${this.state.searchText}`} />;
      }
      return (
        <Router>
          {searchRedirect}
          <nav className="navbar navbar-expand-md navbar-dark bg-dark">
            <div className="navbar-brand mb-0 h1"><span className="current-book">{this.state.userActiveBook.bookName.concat(' book')}</span></div>
            <button className="navbar-toggler" type="button" data-toggle="collapse" data-target="#collapsibleNavbar">
              <span className="navbar-toggler-icon"></span>
            </button>
            <div className="collapse navbar-collapse" id="collapsibleNavbar">
              <ul className="navbar-nav mr-auto">
                <li className="nav-item">
                  <NavLink className="nav-link" to={'/'}>Home</NavLink>
                </li>
                <li className="nav-item">
                  <NavLink className="nav-link" to={'/books'}>Your Recipe Books</NavLink>
                </li>
                <li className="nav-item">
                  <NavLink className="nav-link" to={'/showrecipes'}>Show Recipes</NavLink>
                </li>
                <li className="nav-item">
                  <NavLink className="nav-link" to={'/addrecipes'}>Add Recipes</NavLink>
                </li>
              </ul>
              <form className="form-inline my-2 my-md-0" onSubmit={(event) => this.handleSubmit(event)}>
                <input className="form-control form-control-sm" value={this.state.searchText} onChange={(event) => { this.handleSearchTyping(event) }} type="text" placeholder="Search recipes" />
              </form>
            </div>
          </nav>
          <Switch>
            <Route exact path='/books'>
              <RecipeBooks onGiveAccess={(email, bookId, bookName) => this.handleGiveUserAccess(email, bookId, bookName)} onActiveBookChange={(event) => this.workerActiveBookChange(event)} onDeleteBook={(id) => this.handleDeleteBook(id)} onCreateBook={(name) => this.handleAddBook(name)} workerEmail={this.state.workerEmail} userId={this.state.userId} userBooks={this.state.userBooks} userActiveBook={this.state.userActiveBook} userOwnedBooks={this.state.userOwnedBooks}></RecipeBooks>
            </Route>
            <Route exact path='/searchrecipes/:searchText' render={(props) => <RecipesSearch {...props} firestore={firestore} workerEmail={this.state.workerEmail} bookCreator={this.state.bookCreator} bookOwner={this.state.bookOwner} userActiveBook={this.state.userActiveBook} />} />
            <Route exact path='/showrecipes'>
              <RecipesList firestore={firestore} workerEmail={this.state.workerEmail} bookCreator={this.state.bookCreator} bookOwner={this.state.bookOwner} userActiveBook={this.state.userActiveBook}></RecipesList>
            </Route>
            <Route exact path='/editrecipe/:recipeId' render={(props) => <RecipeEdit {...props} storage={storage} firestore={firestore} workerEmail={this.state.workerEmail} bookCreator={this.state.bookCreator} bookOwner={this.state.bookOwner} userActiveBook={this.state.userActiveBook} />} />
            <Route exact path='/deleterecipe/:recipeId' render={(props) => <RecipeDelete {...props} storage={storage} firestore={firestore} workerEmail={this.state.workerEmail} bookCreator={this.state.bookCreator} bookOwner={this.state.bookOwner} userActiveBook={this.state.userActiveBook} />} />
            <Route exact path='/displayrecipe/:recipeId' render={(props) => <RecipeDisplay {...props} storage={storage} firestore={firestore} workerEmail={this.state.workerEmail} userBooks={this.state.userBooks} bookCreator={this.state.bookCreator} bookOwner={this.state.bookOwner} userActiveBook={this.state.userActiveBook} />} />
            <Route exact path='/addrecipes'>
              <RecipeAdd storage={storage} firestore={firestore} workerEmail={this.state.workerEmail} userActiveBook={this.state.userActiveBook}></RecipeAdd>
            </Route>
            <Route exact path='/'>
              <RecipeHome firestore={firestore} workerEmail={this.state.workerEmail} userActiveBook={this.state.userActiveBook}></RecipeHome>
            </Route>
          </Switch>
        </Router>
      );
    } else {
      return <div className="h3 login-message">You must log in to see/enter recipes.</div>;
    }
  }

  render() {
    return (
      <div className="App">
        <MAFSignIn
          userInitiatedGoogleAuthChange={() => this.userInitiatedGoogleAuthChange()}
          authStateChanged={(user) => this.authStateChange(user)}>
        </MAFSignIn>
        <MAFHeader></MAFHeader>
        {this.renderBody()}
      </div>
    );
  }
}

export default App;
