import React from 'react';

export default class RecipeShow extends React.Component {
    constructor(props) {
        super(props);
        this.props = props;
        this.state = {
        };
    }

    render() {
        return (
            <div id="show-recipe">
                <div className="row">
                    <div className="col-12"><span className="h3">{this.props.recipeTitle}</span></div>
                </div>
                <div className="row"><div className="col-12"><b>Dish type:</b> {this.props.dishType}</div></div>
                <div className="row"><div className="col-12"><b>Entered by:</b> {this.props.enteredByEmail}</div></div>
                <div className="row">
                    <div className="col-12"><b>Recipe categories:</b> {this.props.recipeCategories.toString()}</div>
                </div>
                <div className="row">
                    <div className="col-12">
                        <table className="table table-sm table-striped table-bordered">
                            {this.props.ingredientGroups.map((group) => {
                                return (
                                    <React.Fragment key={group.id}>
                                        <thead>
                                            <tr>
                                                <th>&nbsp;Qty&nbsp;</th>
                                                <th>UOM</th>
                                                <th>Ingredient</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <tr><td colSpan="3">{group.igroupName} Ingredients</td></tr>
                                            {group.ingredients.map((item) => {
                                                return (
                                                    <tr key={item.id}>
                                                        <td className="text-center">{item.qty}</td>
                                                        <td className="text-center">{item.uom}</td>
                                                        <td>{item.ingredient}</td>
                                                    </tr>
                                                );
                                            })}
                                        </tbody>
                                    </React.Fragment>
                                );
                            })}
                        </table>
                    </div>
                </div>
                <div className="row col-10"><b>Recipe instructions:</b></div>
                <div className="row">
                    <div className="col-10">
                        {this.props.recipeInstructions.split('\n').map( (item, index) =>{
                            return (
                                <div key={index}>{item}<br></br></div>
                            );
                        })}
                    </div>
                </div>
            </div>
        );
    }
}