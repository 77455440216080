import React from 'react';
import FormErrors from './FormErrors.js';
import Ingredient from './Ingredient.js';
import {Redirect} from 'react-router-dom';

export default class RecipeEdit extends React.Component {

    constructor(props) {
        super(props);
        this.props = props;
        this.state = {
            recipeId: '',
            recipeTitle: '',            
            dishPhotos: [],
            dishType: '',
            recipeCategories: '',
            recipeInstructions: '',
            ingredientGroups: [{ id: 0, igroupName: 'Main 1', ingredients: [{ id: 0, qty: '', uom: '', ingredient: '' }] }],
            enteredByEmail: '',
            formErrors: { recipeTitle: '', dishType: '', recipeCategories: '', recipeInstructions: '', ingredients: '' },
            rtValid: false,
            dtValid: false,
            rcValid: false,
            riValid: false,
            ingredientsValid: false,
            formValid: true,
            redirect: false,
        };

        this.handleUserInput = this.handleUserInput.bind(this);
        this.handleUserGroupIngredientInput = this.handleUserGroupIngredientInput.bind(this);
        this.handleUserGroupInput = this.handleUserGroupInput.bind(this);
        this.addPhotos = this.addPhotos.bind(this);
        this.addIngredient = this.addIngredient.bind(this);
        this.addIngredientGroup = this.addIngredientGroup.bind(this);
        this.removeIngredient = this.removeIngredient.bind(this);
        this.removeIngredientGroup = this.removeIngredientGroup.bind(this);
        this.renderIngredients = this.renderIngredients.bind(this);
        this.renderRecipePhotos = this.renderRecipePhotos.bind(this);
        this.validateInput = this.validateInput.bind(this);
        this.updateRecipe = this.updateRecipe.bind(this);
        this.cancelEdit = this.cancelEdit.bind(this);
        this.clearForm = this.clearForm.bind(this);
        this.isEmpty = this.isEmpty.bind(this);
        this.setState = this.setState.bind(this);
    }

    componentDidMount = () => {
        const { match: { params } } = this.props;
        let instate = this.state;
        instate.recipeId = params.recipeId;
        //console.log("recipeid",instate.recipeId);
        let firestore = this.props.firestore;
        var docRef = firestore.collection(this.props.userActiveBook.bookName).doc(this.state.recipeId);

        docRef.get().then( (doc) => {
            if (doc.exists) {
                //console.log("Document data:", doc.data());
                var data = doc.data();
                instate.recipeTitle = data.recipeTitle;
                instate.dishType = data.dishType;
                instate.recipeCategories = data.recipeCategories.toString();
                instate.recipeInstructions = data.recipeInstructions;
                instate.ingredientGroups = data.ingredientGroups;
                instate.enteredByEmail = data.enteredByEmail;
                this.setState(instate);
            } else {
                console.log("No such recipe found!");
            }
        }).catch(function (error) {
            console.log("Error getting document:", error);
        });
    }

    handleUserGroupIngredientInput(gid, id, e) {
        let instate = this.state;
        const name = e.target.name;
        const value = e.target.value;
        instate.ingredientGroups[gid].ingredients[id][name] = value;
        this.setState(instate);
    }

    handleUserGroupInput(gid, e) {
        let instate = this.state;
        const name = e.target.name;
        const value = e.target.value;
        instate.ingredientGroups[gid][name] = value;
        this.setState(instate);
    }

    handleUserInput(e) {
        const name = e.target.name;
        const value = e.target.value;
        this.setState({ [name]: value });
    }

    updateRecipe() {
        let instate = this.state;
        this.setState(instate);
        this.validateInput();
        //console.log('enteredByEmail ', this.state.enteredByEmail);
        //console.log('workerEmail ', this.props.workerEmail);
        //console.log('bookOwner ', this.props.bookOwner);
        if (this.state.enteredByEmail === this.props.workerEmail || this.props.bookOwner.includes(this.props.workerEmail) ) {
            if (this.state.rtValid && this.state.dtValid && this.state.rcValid && this.state.riValid && this.state.ingredientsValid) {
                this.props.firestore.collection(this.props.userActiveBook.bookName).doc(this.state.recipeId).set({
                    recipeTitle: this.state.recipeTitle,
                    dishType: this.state.dishType,
                    recipeCategories: this.state.recipeCategories.split(','),
                    ingredientGroups: this.state.ingredientGroups,
                    recipeInstructions: this.state.recipeInstructions
                }, { merge: true })
                .then((docRef) => {
                    console.log("Recipe Document updated with ID ", this.state.recipeId);
                    alert('Recipe successfully updated :)');
                    this.setState({redirect: true});
                })
                .catch((error) => {
                    console.error("Error adding document: ", error);
                });
            }
        } else{
            alert('you are not authorized to edit this recipe');
        }
    }
    
    cancelEdit(){
        this.setState({redirect: true});
    }

    validateInput() {
        let ingredientError = false;
        let instate = this.state;
        instate.formValid = true;
        if (this.isEmpty(this.state.recipeTitle)) {
            instate.rtValid = false;
            instate.formErrors.recipeTitle = 'Recipe Title cannot be blank';
            instate.formValid = false;
            //console.log('yes empty, rtValid is ', this.state.rtValid);
        }
        else {
            instate.rtValid = true;
            instate.formErrors.recipeTitle = '';
            //console.log('not empty, rtValid is ', this.state.rtValid);
        }
        if (this.isEmpty(this.state.dishType)) {
            instate.dtValid = false;
            instate.formErrors.dishType = 'Dish Type cannot be blank';
            instate.formValid = false;
            //console.log('yes empty, dtValid is ', this.state.dtValid);
        }
        else {
            instate.dtValid = true;
            instate.formErrors.dishType = '';
            //console.log('not empty, dtValid is ', this.state.dtValid);
        }
        if (this.isEmpty(this.state.recipeCategories)) {
            instate.rcValid = false;
            instate.formErrors.recipeCategories = 'Recipe Categories cannot be blank';
            instate.formValid = false;
            //console.log('yes empty, rcValid is ', this.state.rcValid);
        }
        else {
            instate.rcValid = true;
            instate.formErrors.recipeCategories = '';
            //console.log('not empty, rcValid is ', this.state.rcValid);
        }
        if (this.isEmpty(this.state.recipeInstructions)) {
            instate.riValid = false;
            instate.formErrors.recipeInstructions = 'Recipe Instructions cannot be blank';
            instate.formValid = false;
            //console.log('yes empty, riValid is ', this.state.riValid);
        }
        else {
            instate.riValid = true;
            instate.formErrors.recipeInstructions = '';
            //console.log('not empty, riValid is ', this.state.riValid);
        }

        this.state.ingredientGroups.forEach((group) => {
            group.ingredients.forEach((item) => {
                if (this.isEmpty(item.qty) || this.isEmpty(item.uom) || this.isEmpty(item.ingredient)) {
                    ingredientError = true;
                }
            })
        });
        if (ingredientError) {
            instate.ingredientsValid = false;
            instate.formErrors.ingredients = 'A value is required in all 3 ingredient input boxes; remove ingredient lines you don\'t need';
            instate.formValid = false;
            //console.log('yes empty, ingredientsValid is ', this.state.ingredientsValid);
        } else {
            instate.ingredientsValid = true;
            instate.formErrors.ingredients = '';
            //console.log('not empty, ingredientsValid is ', this.state.ingredientsValid);
        }
        this.setState(instate);
    }

    clearForm() {
        let instate = this.state;
        instate = {
            recipeTitle: '',
            dishType: '',
            recipeCategories: '',
            recipeInstructions: '',
            ingredientGroups: [{ id: 0, igroupName: 'Group 1', ingredients: [{ id: 0, qty: '', uom: '', ingredient: '' }] }],
            enteredByEmail: '',
            formErrors: { recipeTitle: '', dishType: '', recipeCategories: '', recipeInstructions: '', ingredients: '' },
            rtValid: false,
            dtValid: false,
            rcValid: false,
            riValid: false,
            ingredientsValid: false,
            formValid: true
        };
        this.setState(instate);
    }

    isEmpty(value) {
        return (value == null || value === '');
    }

    addPhotos() {
        let instate = this.state;

        const fileSelector = document.createElement('input');
        fileSelector.type = 'file';
        fileSelector.multiple = true;
        fileSelector.accept = '.jpg, .jpeg, .png';        
        fileSelector.addEventListener('change', (event) => { 
            const fileList = event.target.files; 
            console.log(fileList);
            fileList.forEach((file) =>{
                // Check if the file is an image.
                if (file.type && file.type.indexOf('image') === -1) {
                    console.log('File is not an image.', file.type, file);
                    return;
                }
                const reader = new FileReader();
                reader.addEventListener('load', (event) => {
                    instate.recipePhotos.push(event.target.result);
                    this.setState(instate);
                });
                reader.readAsDataURL(file);  
            });
        });
        fileSelector.click();
      

        var storageRef = this.props.storage.ref();
        var spaceRef = storageRef.child(this.props.bookCreator +'/'+this.props.userActiveBook+'/'+instate.recipeId);

        this.setState(instate);
      }    

    addIngredient(gid) {
        let instate = this.state;
        let size = instate.ingredientGroups[gid].ingredients.length;
        instate.ingredientGroups[gid].ingredients.push({ id: size, qty: '', uom: '', ingredient: '' });
        this.setState(instate);
    }

    addIngredientGroup() {
        let instate = this.state;
        let size = instate.ingredientGroups.length;
        //console.log("Group size is", size);
        instate.ingredientGroups.push({ id: size, igroupName: 'Group ' + (size + 1), ingredients: [{ id: 0, qty: '', uom: '', ingredient: '' }] });
        this.setState(instate);
        //console.log(this.state);
    }

    removeIngredient(gid) {
        let instate = this.state;
        instate.ingredientGroups[gid].ingredients.pop();
        this.setState(instate);
    }

    removeIngredientGroup() {
        let instate = this.state;
        instate.ingredientGroups.pop();
        this.setState(instate);
    }

    renderIngredients(gid, gname) {
        let ingredients = this.state.ingredientGroups[gid].ingredients;
        const ingredientGroup = (
            <div className="group_ingredients">
                <fieldset className="col-12">
                    <legend className="form-control-sm">{gname} Ingredients</legend>                
                    {ingredients.map((item) => (
                    <Ingredient
                        onClick={(event => this.handleUserGroupIngredientInput(gid, item.id, event))}
                        key={''.concat(gid, item.id)} qty={item.qty} uom={item.uom}>
                        {item.ingredient}
                    </Ingredient>
                ))}
                </fieldset>
            </div>
        );
        return ingredientGroup;
    }

    renderRecipePhotos(){
        const photos = (
            <div>
                {this.state.dishPhotos.forEach((photo) =>{
                    return (
                        <img alt="food"></img>
                    );
                })}   
            </div>
        );
        return photos;
    }

    renderIngredientGroups() {
        let ingredientGroups = this.state.ingredientGroups;
        const groups = (
            <div>
                {ingredientGroups.map((group) => {
                    return (
                        <div className='igroup' key={group.id}>
                            <div className="row">
                                <div className="col-12 input-group">
                                    <input name="igroupName"
                                        value={group.igroupName}
                                        onChange={(event => this.handleUserGroupInput(group.id, event))}
                                        className="form-control form-control-sm"
                                        type="text" placeholder="Ingredient group name">
                                    </input>
                                    <div className="input-group-append">
                                        <button onClick={() => this.addIngredient(group.id)} id="add-button" className="btn btn-primary btn-sm">Add Ingredient</button>
                                        <button onClick={() => this.removeIngredient(group.id)} id="remove-button" className="btn btn-secondary btn-sm">Remove Ingredient</button>
                                    </div>
                                </div>

                            </div>
                            {this.renderIngredients(group.id, group.igroupName)}
                        </div>
                    );
                })}
            </div>
        );
        return groups;
    }

    render() {
        if (this.state.redirect) {
            return <Redirect push to="/showrecipes" />;
        }        
        return (
            <div id="add-recipe">
                <div className="container">
                    <div className="card">
                        <h1 className="card-header">Edit a Recipe</h1>
                        <div className="card-body">
                            <FormErrors formValid={this.state.formValid} formErrors={this.state.formErrors} />
                            <div className="row col-6"><b>Recipe Title</b></div>
                            <div className="row col-12 input-group">
                                <input name="recipeTitle" value={this.state.recipeTitle}
                                    onChange={event => this.handleUserInput(event)}
                                    className="form-control form-control-sm"
                                    type="text" placeholder="Recipe Title">
                                </input>
                            </div>
                            <div className="row col-6">
                                <button onClick={() => this.addPhotos()} id="add-photos-button" className="btn btn-primary btn-sm">Add Photos of Dish</button>
                                {this.renderRecipePhotos()}
                            </div>
                            <div className="row col-6"><b>Dish Type</b></div>
                            <div className="row col-8 input-group">
                                <select
                                    name="dishType"
                                    value={this.state.dishType}
                                    className="form-control form-control-sm"
                                    onChange={event => this.handleUserInput(event)}>
                                    <option value="">Dish type</option>
                                    <option value="appetizers_snacks">appetizers &amp; snacks</option>
                                    <option value="breads">breads</option>
                                    <option value="breakfast">breakfast</option>
                                    <option value="cakes">cakes</option>
                                    <option value="candy_fudge">candy &amp; fudge</option>
                                    <option value="cookies_brownies">cookies &amp; brownies</option>
                                    <option value="desserts">desserts</option>
                                    <option value="drinks">drinks</option>
                                    <option value="main-dishes">main dishes</option>
                                    <option value="pies">pies</option>
                                    <option value="sandwiches">sandwiches</option>
                                    <option value="salads">salads</option>
                                    <option value="sauces_condiments">sauces &amp; condiments</option>
                                    <option value="side-dishes">side dishes</option>
                                    <option value="smoothies">smoothies</option>
                                    <option value="soups_stews_chilis">soups, stews, and chilis</option>
                                </select>
                            </div>

                            <div className="row col-6"><b>Recipe Categories</b></div>
                            <div className="row col-12 input-group recipe-categories">
                                <input name="recipeCategories"
                                    value={this.state.recipeCategories}
                                    onChange={event => this.handleUserInput(event)}
                                    className="form-control form-control-sm"
                                    type="text" placeholder="comma separate values: plant-based, beef, grains, slow cooker, holiday, easy preparation, etc">
                                </input>
                            </div>

                            <div className="row">
                                <div className="col-10 input-group">
                                    <button onClick={() => this.addIngredientGroup()} id="add-grouping-button" className="btn btn-primary btn-sm">Add Ingredient Group</button>
                                    <button onClick={() => this.removeIngredientGroup()} id="remove-grouping-button" className="btn btn-secondary btn-sm">Remove Ingredient Group</button>
                                </div>
                            </div>

                            {this.renderIngredientGroups()}

                            <div className="row recipe-instructions col-6"><b>Recipe Instructions</b></div>
                            <div className="row col-12 input-group">
                                <textarea name="recipeInstructions"
                                    value={this.state.recipeInstructions}
                                    onChange={event => this.handleUserInput(event)}
                                    className="form-control form-control-sm"
                                    rows="6"
                                    type="text" placeholder="recipe instructions">
                                </textarea>
                            </div>

                            <div className="row">
                                <div className="col-12">
                                    <button onClick={this.cancelEdit} className="btn btn-warning btn-sm">Cancel</button>
                                    <button onClick={this.updateRecipe} id="submit-button" className="btn btn-primary btn-sm">Update</button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}