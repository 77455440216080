import React from 'react';
import RecipesListWidget from './RecipesListWidget.js';

export default class RecipesSearch extends React.Component {
    //ingredientGroups: [{ id: 0, igroupName: 'Group 1', ingredients: [{ id: 0, qty: '', uom: '', ingredient: '' }] }],
    constructor(props) {
        super(props);
        this.props = props;
        this.state = {
            dishTypeCollection: [],
            recipeCount: 0,
            searchText:'',
        };

        let instate = this.state;
        const { match: { params } } = this.props;
        instate.searchText = params.searchText;
        let searchArray = params.searchText.split(/\s+/);

        //required props
        let firestore = this.props.firestore;
        let bookName = this.props.userActiveBook.bookName;
        
        let previousDishType = 'begin';
        let resultArray=null;
        let recipes = [];
        let previousRecipes = [];
        
        firestore.collection(bookName).orderBy("dishType").orderBy("recipeTitle").get()
            .then((querySnapshot) => {
                querySnapshot.forEach( (doc) => {
                    //console.log(doc);
                    var data = doc.data();
                    for(var word of searchArray){
                        var regex = new RegExp(word, 'gi');
                        resultArray = data.recipeTitle.match(regex);
                        if( resultArray === null){
                            resultArray = data.recipeCategories.toString().match(regex);
                            if(resultArray === null){
                                resultArray = data.recipeInstructions.match(regex);
                                if(resultArray === null){
                                    resultArray = data.ingredientGroups.toString().match(regex);
                                    if(resultArray === null){

                                    } else{
                                        recipes.push({ recipeId: doc.id, recipeTitle: data.recipeTitle, dishType: data.dishType.replace(/_/g, "/"), recipeCategories: data.recipeCategories, ingredientGroups: doc.ingredientGroups, recipeInstructions: data.recipeInstructions, enteredByEmail: data.enteredByEmail });
                                        break;
                                    }
                                } else {
                                    recipes.push({ recipeId: doc.id, recipeTitle: data.recipeTitle, dishType: data.dishType.replace(/_/g, "/"), recipeCategories: data.recipeCategories, ingredientGroups: doc.ingredientGroups, recipeInstructions: data.recipeInstructions, enteredByEmail: data.enteredByEmail });
                                    break;
                                }
                            } else {
                                recipes.push({ recipeId: doc.id, recipeTitle: data.recipeTitle, dishType: data.dishType.replace(/_/g, "/"), recipeCategories: data.recipeCategories, ingredientGroups: doc.ingredientGroups, recipeInstructions: data.recipeInstructions, enteredByEmail: data.enteredByEmail });
                                break;
                            }
                        } else{
                            recipes.push({ recipeId: doc.id, recipeTitle: data.recipeTitle, dishType: data.dishType.replace(/_/g, "/"), recipeCategories: data.recipeCategories, ingredientGroups: doc.ingredientGroups, recipeInstructions: data.recipeInstructions, enteredByEmail: data.enteredByEmail });
                            break;
                        }
                    }
                });
            })
            .then(()=>{
                instate.recipeCount = recipes.length;
                recipes.forEach( (rec) => {
                    if (previousDishType === rec.dishType) {
                        previousRecipes.push({ recipeId: rec.recipeId, recipeTitle: rec.recipeTitle, dishType: rec.dishType, recipeCategories: rec.recipeCategories, ingredientGroups: rec.ingredientGroups, recipeInstructions: rec.recipeInstructions, enteredByEmail: rec.enteredByEmail });
                    } else {
                        if (previousDishType !== 'begin') {
                            instate.dishTypeCollection.push({ dishTypeVisible: true, dishType: previousDishType.concat(" (" + previousRecipes.length + " total)"), recipes: previousRecipes.slice() });
                        }
                        previousRecipes = [];
                        previousDishType = rec.dishType;
                        previousRecipes.push({ recipeId: rec.recipeId, recipeTitle: rec.recipeTitle, dishType: rec.dishType, recipeCategories: rec.recipeCategories, ingredientGroups: rec.ingredientGroups, recipeInstructions: rec.recipeInstructions, enteredByEmail: rec.enteredByEmail });
                    }
                });                
                instate.dishTypeCollection.push({ dishTypeVisible: true, dishType: previousDishType.concat(" (" + previousRecipes.length + " total)"), recipes: previousRecipes.slice() });
                this.setState(instate);
            });
    }

    componentDidMount = () => {
    }

    render() {
        return (
            <RecipesListWidget
                dishTypeCollection={this.state.dishTypeCollection}
                recipeCount={this.state.recipeCount}
                userActiveBook={this.props.userActiveBook}
                searchText={this.state.searchText}
            >
            </RecipesListWidget>
        );
    }
}